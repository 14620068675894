<template>
  <div class="flex flex-row-reverse">
    <div class="flex rounded-md shadow-sm">
      {{ search }}
      <div class="relative flex-grow focus-within:z-10">
        <div
          class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
        >
          <SearchIcon class="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          v-model="query"
          @input="changeQuery"
          name="search"
          class="search hidden w-full rounded-none rounded-l-md rounded-r-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:block sm:text-sm"
          :placeholder="placeholder"
        />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { SearchIcon } from "@heroicons/vue/outline";

export default {
  name: "CustomSearch",
  components: {
    SearchIcon,
  },
  emits: ["update:search"],
  data() {
    return {
      query: "",
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Search",
    },
  },
  methods: {
    changeQuery() {
      debounce(this.updateSearch, 1200)();
    },

    updateSearch() {
      this.$emit("update:search", this.query);
    },
  },
};
</script>
