<template>
  <div
    v-if="pagination.totalResults > 0"
    class="flex items-center justify-between border-l border-r border-gray-200 bg-white px-4 py-3 sm:px-6"
  >
    <div class="flex flex-1 justify-between sm:hidden">
      <a
        href="#"
        class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
      >
        Previous
      </a>
      <a
        href="#"
        class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
      >
        Next
      </a>
    </div>
    <div
      class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between"
      v-if="pagination.totalResults > 0"
    >
      <div>
        <p class="text-sm text-gray-700">
          Showing
          <span class="font-medium">{{ pagination.page }}</span>
          to
          <span class="font-medium">{{ pagination.pageSize }}</span>
          of
          <span class="font-medium">{{ pagination.totalResults }}</span>
          results
        </p>
      </div>
      <div v-if="pagination.pageSize < pagination.totalResults">
        <nav
          class="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <button
            type="button"
            :disabled="pagination.page === 1 ? 'disabled' : null"
            :class="pagination.page === 1 ? 'disabled:opacity-50' : null"
            class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon class="h-5 w-5 text-gray-400" />
          </button>
          <button
            v-for="page in total"
            :key="page"
            type="button"
            @click="setPage(page)"
            class="outline-none relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700"
            :class="
              pagination.page === page
                ? 'z-10 border-indigo-500 bg-indigo-50 text-indigo-600'
                : 'border-gray-300 text-gray-500'
            "
          >
            {{ page }}
          </button>
          <button
            :disabled="pagination.page === 1 ? 'disabled' : null"
            :class="pagination.page === 1 ? 'disabled:opacity-50' : null"
            class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500"
          >
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="h-5 w-5" />
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/outline";

export default defineComponent({
  name: "Pagination",
  components: {
    // SearchIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  props: {
    pagination: Object,
  },
  setup(props, { emit }) {
    let currentPage = 1;

    const total = computed(() =>
      Math.ceil(props.pagination.totalResults / props.pagination.pageSize)
    );

    const setPage = (page) => {
      emit("changePage", page);
    };

    return {
      total,
      setPage,
      currentPage,
    };
  },
});
</script>
