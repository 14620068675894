<template>
  <div class="border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
    <h3 class="text-2xl font-medium leading-6 text-gray-900">{{ name }}</h3>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
  },
};
</script>
