import { ref, readonly } from "vue";

import API from "@/api";
import { ElNotification } from "element-plus";

export default function useRolesRepository() {
  const roles = ref([]);
  const totalResults = ref(0);
  const loading = ref(false);

  const getRoles = async (page, limit) => {
    loading.value = true;
    let response = await API.Admin.Roles.get(page, limit);
    if (response.data.Success) {
      roles.value = response.data.List;
      totalResults.value = response.data.TotalResults;
      loading.value = false;
    } else {
      ElNotification({
        title: "Oops",
        message: "Problem while fetching Roles",
        type: "warning",
      });
    }
  };

  const getAllRoles = async () => {
    loading.value = true;
    let response = await API.Admin.Roles.all();
    if (response.data.Success) {
      roles.value = response.data.List;
      loading.value = false;
    } else {
      ElNotification({
        title: "Oops",
        message: "Problem while fetching Roles",
        type: "warning",
      });
    }
  };

  const deleteRole = async (id) => {
    let response = await API.Admin.Roles.delete(id);
    if (response.data.Success) {
      ElNotification({
        title: "Success",
        message: "Role deleted successfully",
        type: "success",
      });

      getRoles();
    } else {
      ElNotification({
        title: "Oops",
        message: "Problem while deleting Role",
        type: "warning",
      });
    }
  };

  const saveRole = async (role) => {
    loading.value = true;
    let response = await API.Admin.Roles.save(role);

    if (response.data.Success) {
      loading.value = false;

      ElNotification({
        title: "Success",
        message: "Role saved successfully",
        type: "success",
      });

      getRoles();
    } else {
      loading.value = false;
      ElNotification({
        title: "Oops",
        message: "Problem while saving Role",
        type: "warning",
      });
    }
  };

  const editRole = async (role) => {
    let response = await API.Admin.Roles.update(role);
    if (response.data.Success) {
      ElNotification({
        title: "Success",
        message: "Role updated successfully",
        type: "success",
      });
      getRoles();
    } else {
      ElNotification({
        title: "Oops",
        message: "Problem while updating Role",
        type: "warning",
      });
    }
  };

  return {
    roles: readonly(roles),
    loading: readonly(loading),
    saveRole,
    getRoles,
    getAllRoles,
    editRole,
    deleteRole,
    totalResults: readonly(totalResults),
  };
}
