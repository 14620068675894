<template>
  <TransitionRoot appear as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 flex max-w-full pl-10">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div
              class="w-screen"
              :class="size === 'lg' ? 'max-w-2xl' : 'max-w-md'"
            >
              <div
                class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl"
              >
                <div class="bb px-4 sm:px-6">
                  <div class="flex items-start justify-between">
                    <DialogTitle class="pl-6 text-lg font-medium text-gray-900">
                      {{ title }}
                    </DialogTitle>
                    <div class="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        class="focus:outline-none focus:white rounded-md bg-white text-gray-400 hover:text-gray-500"
                        @click="onClose"
                      >
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="relative mt-6 flex-1 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <slot />
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "md",
    },
  },
  setup(_, { emit }) {
    const open = ref(true);

    const onSubmit = () => {
      emit("submit", true);
    };

    const onClose = () => {
      open.value = false;
      emit("onClose", true);
    };

    return {
      open,
      onSubmit,
      onClose,
    };
  },
};
</script>

<style>
.bb {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 16px;
}
</style>
